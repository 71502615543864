<template>
   <div>
      <!--begin::User-->
      <div class="card card-custom">
         <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title m-0">
               <h3 class="card-label m-0">
                  {{ $t("subdomain.invoices_bonds_management") }} :
                  {{ company.business_name_subdomain }}
               </h3>
            </div>
         </div>
         <div class="card-body">
            <div class="card-body p-0">
               <b-form-group label="" v-slot="{ ariaDescribedby }" class="col-12">
                  <b-form-checkbox
                     v-model="selectAllChecked"
                     @change="checkAllInvoicesBond"
                     class="mr-2"
                     >{{ $t("select_all") }}</b-form-checkbox
                     >
                  <!-- Checkbox Group -->
                  <b-form-checkbox-group
                     id="checkbox-group-2"
                     v-model="invoices_bonds"
                     :aria-describedby="ariaDescribedby"
                     name="content-temp-2"
                     class="row"
                     >
                     <b-form-checkbox
                        v-for="(row, index) in invoices_bond_configuration_list"
                        :value="row.slug"
                        :key="index"
                        class="col-md-5 mt-5 mb-5"
                        >
                        {{ row.name }}
                     </b-form-checkbox>
                  </b-form-checkbox-group>
               </b-form-group>
            </div>
            <div class="card-footer pl-0 pr-0">
               <b-button class="mt-2 mr-1" variant="primary" @click="save">{{
                  $t("save")
                  }}
               </b-button>
            </div>
         </div>
      </div>
      <!--end::User-->
   </div>
</template>
<script>
   import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
   import ApiService from "@/core/services/api.service";
   import _ from "lodash";
   
   export default {
     name: "index",
     components: {},
     data() {
       return {
         mainRoute: "/subscription/plans",
         subRoute: "/subscription/plan",
         mainRouteSubdomain: "/subscription/management_subdomains",
         updateInvoicesBonds:
           "/subscription/management_subdomain/update_invoices_bonds",
   
         idEdit: this.$route.params.plan_id ? this.$route.params.plan_id : "",
         company_id: this.$route.params.company_id
           ? this.$route.params.company_id
           : "",
   
         invoices_bonds: [],
         selectAllChecked: false,
         invoices_bond_configuration_list: [],
         company: [],
         validation: null,
         plan_name: null,
       };
     },
     mounted() {
       this.getInvoicesBondsDetails();
       let promise1 = this.getData();
       // let promise2 = this.getActions();
       Promise.all([promise1]).then(() => {
         this.$store.dispatch(SET_BREADCRUMB, [
           {
             title: this.$t("MENU.management_subdomain"),
             route: "/subscription/management-subdomain",
           },
           { title: this.$t("subdomain.invoices_bonds_management") },
         ]);
       });
     },
     methods: {
       save() {
         let _actions = {invoices_bonds: []};
         _actions.invoices_bonds = this.invoices_bonds;
         
         ApiService.patch(
           `${this.updateInvoicesBonds}/${this.company_id}`,
           _actions
         )
           .then(() => {
             this.afterEdit();
           })
           .catch((error) => {
             this.validation = error.response ? error.response.data.errors : null;
             this.$errorAlert(error);
           });
       },
   
       afterEdit() {
         this.$router.push({ name: "management-subdomain.index" });
       },
   
       checkAllInvoicesBond() {
         if (this.selectAllChecked) {
           this.invoices_bonds = this.invoices_bond_configuration_list.map((row) => row.slug);
         } else {
           this.invoices_bonds = [];
         }
       },
   
       async getInvoicesBondsDetails() {
         await ApiService.get(this.subRoute + "/invoices_bond_configuration").then(
           (response) => {
             response.data.data.forEach((row) => {
               if (row.name) {
                 this.invoices_bond_configuration_list.push(row);
               }
             });
           }
         );
       },
   
       async gettInvoicesBondsFromPlan() {
         ApiService.get(`${this.mainRoute}/${this.idEdit}`).then((response) => {
           this.invoices_bonds = response.data.data.invoices_bond_configuration;
         });
       },
   
       async getData() {
         ApiService.get(`${this.mainRouteSubdomain}/${this.company_id}`).then(
           (response) => {
             this.company = response.data.data;
             this.invoices_bonds = response.data.data.invoices_bonds ?? [];
             if (!this.invoices_bonds) {
               this.gettInvoicesBondsFromPlan();
             }
           }
         );
       },
     },
   };
</script>